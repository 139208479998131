import * as React from 'react';
import { memo, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";

import { ModelCardInterface, VisionPromptContentImageInterface } from '@dashart/dashart-gpt-shared-library';
import { ChatDialogMenu } from "@view/components/chatDialogMenu/ChatDialogMenu";
import { ChatInput } from "@view/components/ChatInput/ChatInput";
import { ChatMessageInterface } from '../../../app/interfaces/ChatMessageInterface';
import { FileInfoInterface } from '../../../app/interfaces/FileInfoInterface';
import RecoilStates from "../../../app/models/RecoilStates";
import { ChatHistoryHoc } from '../../components/chatHistory/ChatHistoryHoc';
import ChatMessagesList from '../../components/chatMessagesList/ChatMessagesList';
import ChatOptions from '../../components/chatOptions/ChatOptions';
import { InstructionPanel } from '../../components/instructionPanel/InstructionPanel';
import "./ChatScreen.scss";

interface ChatScreenHocCompletionStateInterface {
    running: boolean;
    currentQuestion: string;
    currentCompletion: string;
    error: string;
    continueOutputAvailable: boolean;
    conversationId: string;
}

export interface ChatScreenProps {
    instruction: string;
    setInstruction: (instruction: string) => void;
    prompt: string;
    setPrompt: (prompt: string) => void;
    images: VisionPromptContentImageInterface[];
    setImages: (images: VisionPromptContentImageInterface[]) => void;
    selectedTextFiles?: FileInfoInterface[];
    setSelectedTextFiles: (selectedTextFiles: FileInfoInterface[] | null) => void;
    messages: ChatMessageInterface[];
    lastUsedConversationId: string;
    availableModels: ModelCardInterface[];
    maxTokens: number;
    setMaxTokens: (maxTokens: number) => void;
    temperature: number;
    setTemperature: (temperature: number) => void;
    gptModel: ModelCardInterface;
    setGptModel: (model: ModelCardInterface) => void;
    completionState: ChatScreenHocCompletionStateInterface;
    requestNewConversation: () => void;
    requestForkOfChatStartingAtGivenMessage: (messageIndex: number) => void;
    requestContinueOfOutput: () => void;
    requestSubmitOfCurrentPrompt: (command?: string) => void;
    requestCancelOfCurrentRequest: () => void;
    requestLoadConversationFromHistory: (conversationId: string, model: string) => void;
}

const ChatScreen = memo(({
    instruction,
    setInstruction,
    prompt,
    setPrompt,
    images,
    setImages,
    selectedTextFiles,
    setSelectedTextFiles,
    messages,
    availableModels,
    maxTokens,
    setMaxTokens,
    temperature,
    setTemperature,
    gptModel,
    setGptModel,
    completionState,
    requestNewConversation,
    requestForkOfChatStartingAtGivenMessage,
    requestContinueOfOutput,
    requestSubmitOfCurrentPrompt,
    requestCancelOfCurrentRequest,
    requestLoadConversationFromHistory
}: ChatScreenProps) => {
    const { t } = useTranslation();
    const [sidebarOpen, setSidebarOpen] = useRecoilState(RecoilStates.chatHistoryOpenState);
    const [textAreaLines, setTextAreaLines] = useState(4);

    const toggleSidebar = useCallback(() => {
        setSidebarOpen(prev => !prev);
    }, [setSidebarOpen]);

    return (
        <div className='ChatScreen flex flex-row h-full w-full'>
            <div className={`h-full ${sidebarOpen ? 'historyOpen' : 'historyClosed'} print-hidden`}>
                <ChatHistoryHoc
                    expanded={sidebarOpen}
                    toggleExpanded={toggleSidebar}
                    onEntrySelected={requestLoadConversationFromHistory}
                />
            </div>

            <div className='h-full flex-grow-1 flex flex-column overflow-hidden'>
                {gptModel?.supportsSystemPrompt && (
                    <InstructionPanel
                        instruction={instruction}
                        onInstructionChange={setInstruction}
                    />
                )}
                <ChatMessagesList
                    className="upperDiv table-container flex-grow-1"
                    onForkRequestHandler={requestForkOfChatStartingAtGivenMessage}
                    messages={messages}
                    completionIsRunning={completionState.running}
                    currentQuestion={completionState.currentQuestion}
                    currentCompletion={completionState.currentCompletion}
                    labelIfNoMessages={t('chat_screen.no_messages')}
                />

                <div className='actionMenu print-hidden'>
                    <ChatDialogMenu
                        messagesExist={messages.length > 0}
                        isRunning={completionState.running}
                        continueOutputAvailable={completionState.continueOutputAvailable}
                        onNewConversation={requestNewConversation}
                        onContinueOutput={requestContinueOfOutput}
                    />
                </div>

                <div className="border-top-1 print-hidden">
                    <ChatOptions
                        completionError={completionState.error}
                        completionIsRunning={completionState.running}
                        availableModels={availableModels}
                        gptModel={gptModel}
                        maxTokens={maxTokens}
                        temperature={temperature}
                        inputTextLines={textAreaLines}
                        setGptModel={setGptModel}
                        setMaxTokens={setMaxTokens}
                        setTemperature={setTemperature}
                        setInputTextLines={setTextAreaLines}
                    />
                    <ChatInput
                        prompt={prompt}
                        model={gptModel}
                        isRunning={completionState.running}
                        textAreaLines={textAreaLines}
                        images={images}
                        selectedTextFiles={selectedTextFiles}
                        onPromptChange={setPrompt}
                        onImagesChange={setImages}
                        onSelectedTextFilesChange={setSelectedTextFiles}
                        onSubmit={requestSubmitOfCurrentPrompt}
                        onCancel={requestCancelOfCurrentRequest}
                    />
                </div>
            </div>
        </div>
    );
});

ChatScreen.displayName = 'ChatScreen';

export default ChatScreen;
