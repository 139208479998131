import { ChatCompletionRequestMessageRoleEnum } from "@app/hooks/useEnhancedChatGpt";
import { ChatCompletionResultInterface } from "@app/interfaces/ChatCompletionResultInterface";
import { ChatMessageInterface } from "@app/interfaces/ChatMessageInterface";
import { FinishReasonEnum } from "@app/models/FinishReasonEnum";
import { ModelCardInterface, VisionPromptContentImageInterface, VisionPromptUtil } from "@dashart/dashart-gpt-shared-library";
import * as React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState, useSetRecoilState } from "recoil";
import { v4 as uuidv4 } from 'uuid';
import { ChatServiceInterface } from "../../../app/interfaces/ChatServiceInterface";
import { FileInfoInterface } from "../../../app/interfaces/FileInfoInterface";
import RecoilStates from "../../../app/models/RecoilStates";
import { ChatHistoryService } from "../../../app/services/ChatHistoryService";
import FilePromptUtil from "../../../app/utils/FilePromptUtil";
import ChatScreen from "./ChatScreen";

const MAX_IMAGES = 5;

export interface ChatScreenHocProps {
    chatService: ChatServiceInterface;
}

const ChatScreenHoc: React.FunctionComponent<ChatScreenHocProps> = (props: ChatScreenHocProps) => {
    const { t } = useTranslation();

    const [instruction, setInstruction] = useState('');
    const [question, setQuestion] = useState('');
    const [images, setImages] = useState<VisionPromptContentImageInterface[]>([]);
    const [selectedTextFiles, setSelectedTextFiles] = useState<FileInfoInterface[] | null>(null);
    const [messages, setMessages] = useState<ChatMessageInterface[]>([]);

    const [lastUsedConversationId, setLastUsedConversationId] = useRecoilState(RecoilStates.lastUsedConversationId);
    const setLastToastMessage = useSetRecoilState(RecoilStates.lastToastMessageState);

    const [maxTokens, setMaxTokens] = useState(null);
    const [temperature, setTemperature] = useState(null);
    const [availableModels, setAvailableModels] = useRecoilState<ModelCardInterface[]>(RecoilStates.availableModels);
    const [selectedModelsByServiceNames, setSelectedModelsByServiceNames] = useRecoilState<{
        [key: string]: ModelCardInterface,
    }>(RecoilStates.selectedModelsByServiceNames);

    const [completionState, setCompletionState] = useState({
        running: false,
        currentQuestion: null,
        currentCompletion: null,
        error: null,
        continueOutputAvailable: false,
        conversationId: uuidv4(),
    });

    /**
     * Set images and validate them, remove images that do not have an imageUrl or content
     * @param images 
     */
    const setAndValidateImages = React.useCallback((images: VisionPromptContentImageInterface[]) => {
        images = images.filter((image) => {
            return image && image.imageUrl;
        });

        // limit length to 5
        if (images.length > MAX_IMAGES) {
            images = images.slice(0, MAX_IMAGES);

            setLastToastMessage({
                severity: "error",
                summary: t(
                    "chat_screen.max_images_reached",
                    {
                        maxImages: MAX_IMAGES,
                        interpolation: { escapeValue: false }
                    }
                ),
            });
        }

        setImages(images);
    }, [images]);

    useEffect(() => {
        // load models
        props.chatService.getAvailableChatModels().then((models) => {
            setAvailableModels(models)
        });

        props.chatService.requestNewConversationId().then((newConversationId) => {
            setCompletionState(
                (prevState) => {
                    setLastUsedConversationId(prevState.conversationId);
                    return {
                        ...prevState,
                        conversationId: newConversationId,
                    }
                }
            )
        });

    }, [props.chatService]);

    /**
     * Select the first model as default, as soon as all models are loaded, but only in case not already selected
     */
    useEffect(() => {
        if (!selectedModelsByServiceNames[props.chatService.modelBaseNameStart] && availableModels.length) {
            setSelectedModelsByServiceNames({
                ...selectedModelsByServiceNames,
                [props.chatService.modelBaseNameStart]: availableModels[0]
            })
        }
    }, [selectedModelsByServiceNames, availableModels]);

    /**
     * Create a new conversation
     */
    const newConversationClickHandler = async () => {
        createNewConversation();
    }

    /**
     * Fork conversation with all messages until provided message index
     * @param messageIndex 
     */
    const forkMessage = async (messageIndex: number) => {
        createNewConversation(
            messages.slice(0, messageIndex + 1)
        );
    }

    /**
     * Create a new conversation and stop the current running completion, if any
     * initialize with provided messages
     * @param initialMessages 
     */
    const createNewConversation = async (initialMessages: ChatMessageInterface[] = []) => {
        // cancel current completion
        props.chatService.cancelCurrentCompletion();

        // request new conversation id
        const newConversationId = await props.chatService.requestNewConversationId();

        // clear messages
        setMessages(initialMessages);
        // clear instruction
        setInstruction('');
        // clear question
        setQuestion('');
        // clear images
        setImages([]);
        // clear files
        setSelectedTextFiles(null);

        // notify about new conversation id
        setLastUsedConversationId(
            completionState.conversationId
        );

        // reset state
        setCompletionState(
            {
                running: false,
                currentQuestion: '',
                currentCompletion: null,
                error: null,
                continueOutputAvailable: false,
                conversationId: newConversationId,
            }
        )
    }

    /**
     * Continue with current output
     */
    const continueClickHandler = async () => {
        submitHandler("continue output");
    }

    /**
     * Trigger chat completion
     *
     * If command is set, it will be used as question
     *
     *
     * @param command
     */
    const submitHandler = async (command?: string) => {
        let userQuestion = command ? command : question;

        // if we have no prompt, return and trigger toast
        if (!userQuestion) {
            setLastToastMessage({
                severity: "error",
                summary: t("chat_screen.no_prompt"),
            });
            return;
        }

        // if text content files are set, append them to the prompt
        if (selectedTextFiles && selectedTextFiles.length > 0) {
            userQuestion = FilePromptUtil.appendTextFilesToPrompt(userQuestion, selectedTextFiles);
        }

        // filter out images that do not have an imageUrl nor content
        const imagesForPrompt = (images ?? []).filter((image) => {
            return image && image.imageUrl;
        });

        if (imagesForPrompt.length > 0) {
            // for images we have to combine prompt and image
            userQuestion = VisionPromptUtil.convertVisionPromptToString(userQuestion, imagesForPrompt);
        }

        // Handle the submission logic here (e.g., fetch answer from an API)

        setCompletionState(
            (prevState) => {
                return {
                    running: true,
                    currentQuestion: userQuestion,
                    currentCompletion: null,
                    error: null,
                    continueOutputAvailable: false,
                    conversationId: prevState.conversationId,
                }
            }
        )

        props.chatService.chatCompletion(
            userQuestion,
            false,
            [
                ...instruction ? [
                    {
                        message: {
                            role: ChatCompletionRequestMessageRoleEnum.System,
                            content: instruction,
                        } as ChatMessageInterface
                    }
                ] : [],
                ...messages.filter((message) => {
                    // ignore empty messages
                    return (message.content ?? '').trim() !== '';
                }).map((messageContent) => {
                    return {
                        message: {
                            role: messageContent.role,
                            content: messageContent.content,
                        } as ChatMessageInterface
                    }
                })
            ],
            (data, prompt, currentCompletion) => {
                setCompletionState(
                    (prevState) => {
                        return {
                            ...prevState,
                            currentCompletion: currentCompletion,
                        }
                    }
                )
            },
            (data: ChatCompletionResultInterface, prompt, currentCompletion) => {
                let canBeContinued = false;
                switch (data?.choices[0]?.finish_reason) {
                    case FinishReasonEnum.length:
                        canBeContinued = true;
                        break;
                    default:
                        canBeContinued = false;
                }


                setCompletionState(
                    (prevState) => {
                        return {
                            running: false,
                            currentQuestion: null,
                            extendedUserQuestion: null,
                            currentCompletion: null,
                            error: null,
                            continueOutputAvailable: canBeContinued,
                            conversationId: prevState.conversationId,
                        }
                    }
                )

                // only if question does not differ from initial prompt
                setQuestion(
                    (currentDisplayedQuestion) => {
                        const isVisionRequest = VisionPromptUtil.isVisionRequest(prompt);
                        // if it is a vision request, we need to validate the prompt against the current displayed question,
                        // if it is not a vision request, we can directly compare the prompt with the current displayed question
                        let currentPromptToBeValidated = isVisionRequest ? VisionPromptUtil.convertVisionPromptToContent(prompt)?.prompt ?? prompt : prompt;

                        if (selectedTextFiles && selectedTextFiles.length > 0) {
                            // remove files from prompt
                            currentPromptToBeValidated = FilePromptUtil.convertToPromptWithoutFiles(currentPromptToBeValidated, selectedTextFiles);
                        }

                        if (currentDisplayedQuestion.trim() === currentPromptToBeValidated.trim()) {
                            // reset the images too
                            setImages([]);
                            // clear files
                            setSelectedTextFiles(null);
                            return '';
                        } else {
                            return currentDisplayedQuestion;
                        }
                    }
                );

                setMessages((prevMessages) => [
                    ...prevMessages,
                    { role: ChatCompletionRequestMessageRoleEnum.User, content: prompt },
                    { role: ChatCompletionRequestMessageRoleEnum.Assistant, content: currentCompletion }
                ]);
            },
            selectedModelsByServiceNames[props.chatService.modelBaseNameStart]?.id,
            maxTokens,
            completionState.conversationId,
            temperature,
        ).catch((error) => {
            setCompletionState(
                (prevState) => {
                    return {
                        running: false,
                        currentQuestion: null,
                        extendedUserQuestion: null,
                        currentCompletion: null,
                        error: error?.message ?? 'unknown error',
                        continueOutputAvailable: false,
                        conversationId: prevState.conversationId,
                    }
                }
            )
        });
    };

    /**
     * Cancel the current completion
     */
    const cancelHandler = () => {
        props.chatService.cancelCurrentCompletion();

        setCompletionState(
            (prevState) => {

                // save current message state
                setMessages((prevMessages) => [
                    ...prevMessages,
                    { role: ChatCompletionRequestMessageRoleEnum.User, content: prevState.currentQuestion },
                    { role: ChatCompletionRequestMessageRoleEnum.Assistant, content: prevState.currentCompletion }
                ]);

                // reset state
                return {
                    running: false,
                    currentQuestion: null,
                    currentCompletion: null,
                    error: null,
                    continueOutputAvailable: false,
                    conversationId: prevState.conversationId,
                }
            }
        )
    }

    /**
     * Load a conversation from history
     * @param conversationId 
     */
    const loadConversationFromHistory = async (conversationId: string, model: string): Promise<void> => {
        // load history
        try {
            const history = await ChatHistoryService.getInstance().getConversationHistory(conversationId);
            const instruction = history?.messages.find((historyEntry) => {
                return historyEntry.message.role === ChatCompletionRequestMessageRoleEnum.System;
            });

            // clear images
            setImages([]);

            setInstruction(instruction?.message.content);

            setMessages(history?.messages
                .filter((historyEntry) => {
                    // avoid system messages
                    return historyEntry.message.role !== ChatCompletionRequestMessageRoleEnum.System;
                })
                .map((historyEntry) => {
                    return {
                        role: historyEntry.message.role,
                        content: historyEntry.message.content,
                    }
                }));

            const chatServiceModel = availableModels.find((m) => m.id === model);
            console.log('loadConversationFromHistory 2', {
                'model': model,
                'service': props.chatService.modelBaseNameStart,
                'availableModels': availableModels,
            });
            setSelectedModelsByServiceNames({
                ...selectedModelsByServiceNames,
                [props.chatService.modelBaseNameStart]: chatServiceModel
            });
        } catch (error) {
            console.error(error);
            setLastToastMessage({
                severity: "error",
                summary: t(
                    "chat_screen.load_history_failed",
                    {
                        conversationId: conversationId,
                        interpolation: { escapeValue: false }
                    }
                ),
                detail: error.message
            });
        }
    }

    const onChatModelSelected = (model: ModelCardInterface) => {
        setSelectedModelsByServiceNames({
            ...selectedModelsByServiceNames,
            [props.chatService.modelBaseNameStart]: model
        });
    }

    return <ChatScreen
        instruction={instruction}
        setInstruction={setInstruction}
        prompt={question}
        setPrompt={setQuestion}
        images={images}
        setImages={setAndValidateImages}
        selectedTextFiles={selectedTextFiles}
        setSelectedTextFiles={setSelectedTextFiles}
        messages={messages}

        lastUsedConversationId={lastUsedConversationId}
        maxTokens={maxTokens}
        setMaxTokens={setMaxTokens}
        temperature={temperature}
        setTemperature={setTemperature}
        availableModels={availableModels}
        gptModel={selectedModelsByServiceNames[props.chatService.modelBaseNameStart]}
        setGptModel={onChatModelSelected}
        completionState={completionState}

        requestNewConversation={newConversationClickHandler}
        requestForkOfChatStartingAtGivenMessage={forkMessage}
        requestContinueOfOutput={continueClickHandler}
        requestSubmitOfCurrentPrompt={submitHandler}
        requestCancelOfCurrentRequest={cancelHandler}
        requestLoadConversationFromHistory={loadConversationFromHistory}
    />
}

export default ChatScreenHoc;