import { FileInfoInterface } from "../interfaces/FileInfoInterface";

export class FileSelectorUtil {
    /**
     * List of files to ignore.
     */
    private static readonly ignoredFiles = [
        '.DS_Store',
        'Thumbs.db',
        '.git',
        'node_modules',
        '.env',
        '.idea',
        '.vscode',
        '*.log',
        '*.tmp',
        '*.temp',
    ];

    /**
     * Checks if a file should be ignored.
     * 
     * @param {string} filename - The name of the file to check
     * @returns {boolean} True if the file should be ignored, false otherwise
     */
    public static shouldIgnoreFile = (filename: string): boolean => {
        return this.ignoredFiles.some(pattern => {
            if (pattern.includes('*')) {
                const regex = new RegExp('^' + pattern.replace('*', '.*') + '$');
                return regex.test(filename);
            }
            return filename === pattern || filename.includes(pattern);
        });
    };

    /**
     * Detects if the content of a file is text.
     * Will return false if the content is binary.
     * 
     * @param {ArrayBuffer} content - The content of the file to check
     * @returns {boolean} True if the content is text, false otherwise
     */
    public static isTextContent = (content: ArrayBuffer): boolean => {
        // Check a sample of the file (first 1024 bytes)
        const sampleLength = Math.min(1024, content.byteLength);
        const sampleView = new Uint8Array(content, 0, sampleLength);

        // Text files should mostly contain printable ASCII characters
        // and common control characters (newline, tab, etc.)
        let textCount = 0;
        let nullCount = 0;

        for (let i = 0; i < sampleView.length; i++) {
            const byte = sampleView[i];

            // Count null bytes (common in binary files)
            if (byte === 0) {
                nullCount++;
            }

            // Check if byte is in printable ASCII range or is a common control character
            if (
                (byte >= 32 && byte <= 126) || // Printable ASCII
                byte === 9 ||                   // Tab
                byte === 10 ||                  // Line feed
                byte === 13                     // Carriage return
            ) {
                textCount++;
            }
        }

        // If more than 30% are null bytes, probably binary
        if (nullCount / sampleLength > 0.3) {
            return false;
        }

        // If more than 85% are text characters, probably text
        return (textCount / sampleLength) > 0.85;
    };

    /**
     * Reads the content of a file.
     *
     * @param file - The file to read
     * @returns The content of the file or undefined if the file is not a text file
     */
    public static readFileContent = async (file: File): Promise<string | undefined> => {
        try {
            // First read as ArrayBuffer to detect if it's text
            const buffer = await file.arrayBuffer();

            if (FileSelectorUtil.isTextContent(buffer)) {
                // If it's text, read as text
                return await file.text();
            }
            return undefined;
        } catch (error) {
            console.error('Error reading file:', error);
            return undefined;
        }
    };

    /**
     * Processes a file entry.
     * Will be called recursively for directories.
     * For files, it will read the content and add it to the files array.
     *
     * @param entry - The file entry to process
     * @returns The files found in the entry
     */
    public static processFileEntry = async (entry: FileSystemEntry): Promise<FileInfoInterface[]> => {
        const files: FileInfoInterface[] = [];

        const processEntry = async (entry: FileSystemEntry, path: string = '') => {
            if (FileSelectorUtil.shouldIgnoreFile(entry.name)) {
                return;
            }

            if (entry.isFile) {
                const fileEntry = entry as FileSystemFileEntry;
                return new Promise<void>((resolve) => {
                    fileEntry.file(async (file) => {
                        if (!file.name.startsWith('.')) {
                            const fileArrayBuffer = await file.arrayBuffer();
                            const isTextContent = FileSelectorUtil.isTextContent(fileArrayBuffer);
                            const fileInfo: FileInfoInterface = {
                                path: path + '/' + file.name,
                                name: file.name,
                                extension: FileSelectorUtil.getFileExtension(file.name),
                                type: isTextContent ? 'Text' : 'Binary',
                                size: file.size,
                                isSelected: false,
                                textContent: isTextContent ? await this.readFileContent(file) : null,
                            };

                            // Try to read content
                            const content = await FileSelectorUtil.readFileContent(file);
                            if (content !== undefined) {
                                files.push(fileInfo);
                            }
                        }
                        resolve();
                    });
                });
            } else if (entry.isDirectory) {
                const dirEntry = entry as FileSystemDirectoryEntry;
                const dirReader = dirEntry.createReader();

                const readEntries = (): Promise<FileSystemEntry[]> => {
                    return new Promise((resolve) => {
                        dirReader.readEntries(async (entries) => {
                            if (entries.length > 0) {
                                const moreEntries = await readEntries();
                                resolve([...entries, ...moreEntries]);
                            } else {
                                resolve([]);
                            }
                        });
                    });
                };

                const entries = await readEntries();
                const newPath = path ? path + '/' + entry.name : entry.name;

                await Promise.all(
                    entries.map((entry) => processEntry(entry, newPath))
                );
            }
        };

        await processEntry(entry);
        return files;
    };

    /**
     * Processes a file.
     * @param file 
     * @returns 
     */
    public static async processFile(file: File): Promise<FileInfoInterface[]> {
        try {
            const textContent = await file.text();
            return [{
                name: file.name,
                path: file.webkitRelativePath || file.name,
                type: file.type,
                size: file.size,
                extension: file.name.split('.').pop() || '',
                textContent,
                isSelected: false
            }];
        } catch (error) {
            console.error('Error processing file:', error);
            return [];
        }
    }

    /**
     * Gets the extension of a file.
     * 
     * @param {string} filename - The name of the file to get the extension from
     * @returns {string} The extension of the file
     */
    public static getFileExtension = (filename: string): string => {
        const parts = filename.split('.');
        return parts.length > 1 ? parts[parts.length - 1] : '';
    };

    /**
     * Filter provided files list by provided extensions
     * @param files 
     * @param extensions 
     * @returns 
     */
    public static filterFilesByExtensions = (files:FileInfoInterface[], extensions:string[]) => {
        if((extensions ?? []).length === 0) {
            // no active filter
            return files;
        } else {
            return files.filter(file => extensions.includes(file.extension))
        }
    }
}
