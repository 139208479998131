import { FileInfoInterface } from "../interfaces/FileInfoInterface";

const FILE_SEPARATOR = '\n\n';

export default class FilePromptUtil {
    /**
     * Appends text files to a prompt string.
     * 
     * @param prompt - The prompt to append the text files to
     * @param textFiles - The text files to append
     * @returns The prompt string with the text files appended
     */
    public static appendTextFilesToPrompt = (prompt: string, textFiles: FileInfoInterface[]): string => {
        if (textFiles.length === 0) {
            return prompt;
        }
        return prompt + FILE_SEPARATOR + textFiles
            .map((file) => {
                return `--- FILE START ${file.path} ---\n${file.textContent}\n--- FILE END ${file.path} ---`;
            })
            .join(FILE_SEPARATOR);
    }

    /**
     * Removes files from a prompt.
     * 
     * @param prompt - The prompt to remove files from
     * @param files - The files to remove
     * @returns The prompt without the files
     */
    public static convertToPromptWithoutFiles = (prompt: string, files: FileInfoInterface[]): string => {
        if (files.length === 0) {
            return prompt;
        }
        
        let modifiedPrompt = prompt;
        // remove files from prompt
        files.forEach((file) => {
            modifiedPrompt = modifiedPrompt.replace(
                `--- FILE START ${file.path} ---\n${file.textContent}\n--- FILE END ${file.path} ---`,
                ''
            );
        });
    
        // Calculate how many file separators to remove based on the number of files removed, plus one for the leading separator
        const separatorsToRemove = ((files.length  - 1) + 1) * FILE_SEPARATOR.length;
        modifiedPrompt = modifiedPrompt.slice(0, -separatorsToRemove);
    
        return modifiedPrompt;
    }
}
