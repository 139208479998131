import * as React from "react";
import {CSSProperties} from "react";

export const FolderPlusIcon: React.FC<{ className?: any, color?:string,width: number, height: number }> = (props) => {

    const style: CSSProperties = {
        strokeLinecap: 'round',
        strokeLinejoin: 'round',
        fill: props.color ? props.color : '#000000',
    }

    return <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <g>
            <path
                style={style}
                d="M251.7 127.6s0 0 0 0c10.5 10.5 24.7 16.4 39.6 16.4L448 144c8.8 0 16 7.2 16 16l0 256c0 8.8-7.2 16-16 16L64 432c-8.8 0-16-7.2-16-16L48 96c0-8.8 7.2-16 16-16l133.5 0c4.2 0 8.3 1.7 11.3 4.7l33.9-33.9L208.8 84.7l42.9 42.9zm33.9-33.9L242.7 50.7c-12-12-28.3-18.7-45.3-18.7L64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l384 0c35.3 0 64-28.7 64-64l0-256c0-35.3-28.7-64-64-64L291.3 96c-2.1 0-4.2-.8-5.7-2.3zM232 376c0 13.3 10.7 24 24 24s24-10.7 24-24l0-64 64 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-64 0 0-64c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 64-64 0c-13.3 0-24 10.7-24 24s10.7 24 24 24l64 0 0 64z"
            />
        </g>
    </svg>
}