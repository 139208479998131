import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { MultiSelect } from 'primereact/multiselect';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FileInfoInterface } from '../../../app/interfaces/FileInfoInterface';
import { FileSelectorUtil } from '../../../app/utils/FileSelectorUtil';

// support directory
declare module 'react' {
    interface InputHTMLAttributes<T> extends HTMLAttributes<T> {
        webkitdirectory?: string;
        mozdirectory?: string;
        directory?: string;
    }
}

interface DirectoryReaderProps {
    selectAllFilesOnDrop:boolean,
    selectedTextFiles: FileInfoInterface[],
    onSelectedTextFilesContentsChanged: (filesContents: FileInfoInterface[] | null) => void;
}

const DirectoryReader: React.FC<DirectoryReaderProps> = ({ selectAllFilesOnDrop, onSelectedTextFilesContentsChanged, selectedTextFiles }) => {
    const { t } = useTranslation();
    const [filesWithTextContent, setFilesWithTextContent] = useState<FileInfoInterface[]>([]);
    const [isDragging, setIsDragging] = useState(false);
    const [filterExtensions, setFilterExtensions] = useState<string[]>([]);
    const [availableExtensions, setAvailableExtensions] = useState<string[]>([]);
    const fileInputRef = useRef<HTMLInputElement>(null);

    /**
     * If selectedTextFiles are provided, set the filesWithTextContent to the selectedTextFiles
     */
    useEffect(() => {
        if(selectedTextFiles) {
            setFilesWithTextContent(selectedTextFiles);
            setAvailableExtensions(Array.from(new Set(selectedTextFiles.map((f) => f.extension))));
        }
    }, [selectedTextFiles]);

    /**
     * Process dropped items
     * @param items - The dropped items
     * @returns The processed files
     */
    const processDroppedItems = async (items: DataTransferItemList) => {
        const filePromises: Promise<FileInfoInterface[]>[] = [];

        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            const entry = item.webkitGetAsEntry();
            if (entry) {
                filePromises.push(FileSelectorUtil.processFileEntry(entry));
            }
        }

        const fileArrays = await Promise.all(filePromises);
        return fileArrays.flat();
    };

    /**
     * Process selected files
     * @param files - The selected files
     * @returns The processed files
     */
    const processSelectedFiles = async (files: FileList) => {
        const filePromises: Promise<FileInfoInterface[]>[] = [];

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            filePromises.push(FileSelectorUtil.processFile(file));
        }

        const fileArrays = await Promise.all(filePromises);
        return fileArrays.flat();
    };

    /**
     * Update the files state
     * @param files - The files to update
     */
    const updateFilesState = (files: FileInfoInterface[]) => {
        const processedFiles = files.map(file => ({
            ...file,
            isSelected: selectAllFilesOnDrop,
        }));

        setFilesWithTextContent(processedFiles);
        setAvailableExtensions(Array.from(new Set(processedFiles.map((f) => f.extension))));
    };

    /**
     * Handle the drop event
     * @param event - The drop event
     */
    const handleDrop = useCallback(async (event: React.DragEvent) => {
        event.preventDefault();
        setIsDragging(false);
        const files = await processDroppedItems(event.dataTransfer.items);
        updateFilesState(files);
    }, [selectAllFilesOnDrop]);

    /**
     * Handle the file input event
     * @param event - The file input event
     */
    const handleFileInput = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const files = await processSelectedFiles(event.target.files);
            updateFilesState(files);
        }
    };

    /**
     * Handle the zone click event
     */
    const handleZoneClick = () => {
        fileInputRef.current?.click();
    };

    /**
     * Trigger onSelectedTextFilesContentsChanged on selection changes
     */
    useEffect(() => {
        onSelectedTextFilesContentsChanged(
            // take the already filtered files
            FileSelectorUtil.filterFilesByExtensions(filesWithTextContent ?? [], filterExtensions)
            // only selected files
            .filter(entry => entry.isSelected)
        )
    }, [filesWithTextContent, filterExtensions])

        /**
     * Handles the selection of a file.
     *
     * @param {FileInfoInterface} file - The file to select
     */
    const handleSelectFile = (file: FileInfoInterface) => {
        const updatedFiles = filesWithTextContent.map((f) =>
            f.path === file.path ? { ...f, isSelected: !f.isSelected } : f
        );
        setFilesWithTextContent(updatedFiles);
    };

    /**
     * Handles the selection of all files.
     */
    const handleSelectAll = () => {
        const updatedFiles = filesWithTextContent.map((f) => ({ ...f, isSelected: true }));
        setFilesWithTextContent(updatedFiles);
    };

    /**
     * Handles the deselection of all files.
     */
    const handleDeselectAll = () => {
        const updatedFiles = filesWithTextContent.map((f) => ({ ...f, isSelected: false }));
        setFilesWithTextContent(updatedFiles);
    };

    /**
     * Renders the file template.
     *
     * @param {FileInfoInterface} rowData - The file data
     * @returns {JSX.Element} The file template
     */
    const fileTemplate = (rowData: FileInfoInterface) => (
        <div>
            <Checkbox
                checked={rowData.isSelected}
                onChange={() => handleSelectFile(rowData)}
            />
            <span className="ml-2">{rowData.path}</span>
        </div>
    );

    /**
     * Renders the extension filter and actions.
     *
     * @returns {JSX.Element} The extension filter and actions
     */
    const extensionFilterAndActions = (
        <div className="flex flex-row align-items-center">
            <span className="mr-2">{t('components.directory_reader.filter_extensions')}:</span>
            <MultiSelect
                data-testid="extension-filter"
                value={filterExtensions}
                onChange={(e) => setFilterExtensions(e.value as string[])}
                options={availableExtensions}
                placeholder={t('components.directory_reader.filter_extensions')}
                className="w-full md:w-20rem"
            />
            <div className="flex-grow-1"></div>
            <Button
                label={t('components.directory_reader.select_all')}
                onClick={handleSelectAll}
                className="p-button-primary p-button-sm mr-2"
            />
            <Button
                label={t('components.directory_reader.deselect_all')}
                onClick={handleDeselectAll}
                className="p-button-primary p-button-sm"
            />
        </div>
    );

    return (
        <div className="flex flex-column h-full w-full">
            <div
                onClick={handleZoneClick}
                onDragOver={(e) => {
                    e.preventDefault();
                    setIsDragging(true);
                }}
                onDragLeave={(e) => {
                    e.preventDefault();
                    setIsDragging(false);
                }}
                onDrop={handleDrop}
                className={`flex flex-column align-items-center justify-content-center cursor-pointer
                    ${isDragging ? 'border-primary' : ''}`}
                style={{ minHeight: '100px' }}
                data-testid="directory-reader-drop-zone"
            >
                <input
                    type="file"
                    data-testid="file-input"
                    ref={fileInputRef}
                    onChange={handleFileInput}
                    style={{ display: 'none' }}
                    multiple
                    // support directory
                    webkitdirectory=""
                    mozdirectory=""
                    directory=""
                />
                <span className="p-icon pi pi-folder" />
                <p>{t('components.directory_reader.drag_and_drop_directory_here')}</p>
            </div>

            <DataTable
                value={FileSelectorUtil.filterFilesByExtensions(filesWithTextContent, filterExtensions)}
                sortField="name"
                sortMode="single"
                dataKey="path"
                header={extensionFilterAndActions}
                scrollable
                scrollHeight="flex"
            >
                <Column
                    field="name"
                    header={t('components.directory_reader.name')}
                    style={{width:'auto'}}
                    body={fileTemplate}
                />
                <Column
                    field="extension"
                    style={{width:'2rem'}}
                    header={t('components.directory_reader.extension')}
                />
                <Column
                    field="type"
                    style={{width:'2rem'}}
                    header={t('components.directory_reader.type')}
                />
                <Column
                    field="size"
                    header={t('components.directory_reader.size')}
                    style={{width:'5rem'}}
                    body={(rowData: FileInfoInterface) => `${(rowData.size / 1024).toFixed(2)} KB`}
                />
            </DataTable>
        </div>
    );
};

export default DirectoryReader;