import { Dialog } from 'primereact/dialog';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DirectoryReader from './DirectoryReader';
import { Button } from 'primereact/button';
import { FileInfoInterface } from '../../../app/interfaces/FileInfoInterface';

interface DirectoryReaderDialogProps {
    selectAllFilesOnDrop: boolean;
    selectedTextFiles: FileInfoInterface[];
    onCloseRequest: () => void;
    setSelectedTextFiles: (selectedTextFiles: FileInfoInterface[] | null, closeDialog: boolean) => void;
}

const DirectoryReaderAsDialog: React.FC<DirectoryReaderDialogProps> = ({ selectAllFilesOnDrop, onCloseRequest, setSelectedTextFiles: setSelectedFiles, selectedTextFiles }) => {
    const { t } = useTranslation();
    const [currentSelectedTextFiles, setCurrentSelectedTextFiles] = useState<FileInfoInterface[] | null>(null);

    const onConfirmSelection = useCallback(() => {
        setSelectedFiles(currentSelectedTextFiles, true);
    }, [currentSelectedTextFiles, setSelectedFiles, onCloseRequest]);

    return (
        <Dialog
            header={t('components.directory_reader_overlay.header')}
            visible={true}
            onHide={onCloseRequest}
            style={{ width: '80vw', maxWidth: '80vw', height: '80vh', overflow: 'auto', whiteSpace: 'pre-wrap' }}
            footer={<>
                <Button label={t('components.directory_reader_overlay.cancel')} onClick={onCloseRequest} />
                <Button label={t('components.directory_reader_overlay.select')} onClick={onConfirmSelection} />
            </>}
        >
            <DirectoryReader
                selectAllFilesOnDrop={selectAllFilesOnDrop}
                onSelectedTextFilesContentsChanged={setCurrentSelectedTextFiles}
                selectedTextFiles={selectedTextFiles}
            />
        </Dialog>
    );
};

export default DirectoryReaderAsDialog;